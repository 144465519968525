.contacto__container{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contacto__banner{
    
    background-image:url(../../img/contacto3.webp);
    background-size: cover;
    position: inherit;
    height:50vh;
    
    @include tablet{
        background-image:url(../../img/contacto4.webp);
    }
        
}

.contacto__2boxes{
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @include tablet{
        flex-direction: row;
        align-items: flex-start;
    }
    .contacto__links{
        width: 95%;
        

        @include tablet{
            width: 45%;
            justify-content: left;
            hr{
                width:40vw;
                align:left;
                
            }
        }
    }
}
//contacto__texto es la clase del form
.contacto__texto{

    width: 95%;
    justify-content: center;
    @include tablet{
        position: relative;
        justify-content: left;
        width: 47%;
        top: 0%;
        padding-left: 3rem;
        
    }

    .campo1{
        display: flex;
        margin-bottom: 3rem;
        flex-direction: column;
        //text-align: center;

        h2{
            text-align: left;
        }
    }

    .campo{
        display: flex;
        
        flex-direction: column;

        .campo__label{
            flex: 0 0 2rem;
            text-align: left;
            
            
        }

        .campo__field{
            flex: 1;
            border:0.1px solid rgb(235, 235, 235);
            border-radius: 5px;
            padding: 1rem;
            margin-bottom: 2rem;
            &:focus { 
                outline-color: rgb(173, 173, 173);
            }
            
        }

        .campo__textarea {
            height: 10rem;

        }
    }

}

.contenedor__3d{
    margin-top: 10%;
    height: 100vh;
    width: 100vw;
    @include tablet {
        margin-top: 5%;
    }
}

.contacto__form{
    border:3px solid green;
    width:80vw;
    height: 30vh;  
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;   


}


.contacto__instructs{
    .instructs {
        width: 40%;
        margin: 0;
        text-align: center;
        color: #FFF;
      }
}