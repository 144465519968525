.footer{

    background-color:black;
    display: grid;
	width: 100%;
	font-family: $fuente;
	
	//height:30rem;
    grid-template-rows: 1fr 1fr 0.5fr 0.5fr;
    grid-gap: 1rem;
    @include tablet{
		grid-template-columns: 1fr 1fr 1fr;
    	grid-template-rows: 2fr 1fr;
    	grid-gap: 2rem; 
	}
    
	p,a{
		color: white;
		margin:0.5rem;
		font-size: 1.2rem;
		@include desktop{
			font-size: 1.5rem;
		}	
	}
	li {
		display: inline;
		padding: $separacion_peque;
		margin-right: $separacion_peque;
	}
	a, p {
		display: inline-block;
        color: white;
    }

	.item1{
		//background:LightSkyBlue;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		@include tablet {
			
		}
	}
  	.item2{
		//background:LightSalmon;
		display: flex;
		//flex-direction: column;
		justify-content: space-around;
		align-items: center;
		p, a{
			margin: 0.2rem;
		}

		@include tablet{
			flex-direction: row;
			align-items: center;
			justify-content: space-around;

			p, a{
				margin: 0.5rem;
			}
		}

		.footer_flex{
			display:flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}

	.item4{
		//background:lightseagreen;
		display: flex;
		//flex-direction: column;
		justify-content: center;
		align-items: left;
		@include tablet {
			//flex-direction: column;
			//margin-left:25%
			justify-content: center;
			align-items: center;
		}
	}
  	.item3{
		//background:PaleTurquoise;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		text-align: center;		
		@include tablet{
			grid-column: 1/4;
			grid-row:2/3;
		}
	}
	

}
.footer_logo1{
	width: 45px;
    height:45px;
	
	
    
    @include tablet{
        width: 70px;
        height:60px; 
        
    }
    @include desktop {
        width: 86px;
        height:75px;
        
    }
    @include tv {
        width: 70%;
        height:70%;
    }
}
.footer__campo{
	display:flex;
	justify-content: center;
	align-items: center;
	
	
}
.footer__campo1{
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}

.footer__extrasep{
	padding-right: 2rem;
}

.color{
	color:white;
	margin: 0;
}