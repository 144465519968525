.navbar {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    position: fixed;
    width:100vw;
    // background:linear-gradient(white,95%,transparent);
    z-index: 99;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,1+95,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 95%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 95%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

    @include tablet {
        // background:linear-gradient(white,80%,transparent);
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,1+80,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

    }
    @include desktop{
        background: transparent;
    }
}

//logo

.navbar__logo{

    width: 20%;
    height:20%;
    cursor:pointer;
    margin: 0.5rem 0 0 1rem;

    @include telefono {
        width: 15%;
        height:15%;
    }
    @include tablet{
        width: 10%;
        height:10%; 
        margin:1rem 0 2rem 2rem;
    }
    @include desktop {
        width: 7%;
        height:7%;
        
    }
    @include tv {
        width: 5%;
        height:5%;
    }
}

//links

.navbar__links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    padding:0.3rem;
    align-items: center;
    
    //border: 1px solid red;

    @include tablet{
        flex-direction: row;
        padding:1rem;
        
        
    }

    .navbar__menu {  
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        z-index: 2;
        @include tablet{
            flex-direction: row;
        }
        
    }
    
    .navbar__menu1{
        
        width: 100%;
    }

    .navbar__links-box1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include tablet{
            flex-direction: row;
            
        }
    }

    .navbar__links-box2{
        display: flex;
        align-items: baseline;
        justify-content: space-between;        
    }

    
    .navbar__link p{
        font-weight:lighter;
        font-size: $p_tel;
        margin: 1rem 2rem;
        color: black;
        text-align: center;
            
        @include tablet{
            font-size: $p_tab;
            
        }
        @include desktop{
            font-size: $p_des;
        }
    }
}


//hamburger

.navbar__hamburger {
    //display: none;
    border: none;
    z-index: 1;
    background-color: transparent;
    opacity:0.3;
    margin-right: 1rem;
    transition:opacity .8s;
    transition: transform 2s;
    &:hover{
        opacity: 1;
        transform: rotate(90deg);
        background-color:transparent ;
    }

    @include tablet {
        margin-right: 3rem;
    }

}

.navbar__bar1,
.navbar__bar2,
.navbar__bar3 {
    display: block;
    width: 25px;
    height: 2px;
    margin: 6px auto;
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: #101010;
}

.fa-instagram{
    color:rgb(187, 187, 187);
    margin: 1rem 2rem;

    &:hover{
        color: black;
    }
}

.fa-envelope{
    color:rgb(187, 187, 187);
    margin: 1rem 2rem;
    &:hover{
        color: black;
    }
}







