.legal__texto {

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 1px solid black;

    padding:5%;

    h3 {
        justify-content: center;
        align-items: center;
        
    }

    @include tablet {

        padding:10%;

    }

    @include desktop {

        padding:5%;

    }

}