.introduccion{

  
    
    .introduccion__titulo{
    
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        
        h4{
            font-size: 2.9rem;
            font-weight:$bold;
            margin-top:2rem;
            margin-bottom: 3rem;
            @include tablet{
              font-size: 3.5rem;
              margin-top:4rem;
              margin-bottom: 5rem;
            }
        }
        p{
          font-weight: $delgada;
          padding-left:10% ;
          padding-right:10% ;
          @include tablet{
          font-size: 1.8rem;
          padding-left:20% ;
          padding-right:20% ;
          }
          @include desktop{
            font-size: $p_des;
            padding-left:30% ;
            padding-right:30% ;
          }
        }
        
    }

    .introduccion__tema{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        
        h3{
          margin-top: 7rem;
          font-size: 2.5rem;
          font-weight: $bold;
          @include tablet {
            font-size:3.5rem;
            margin-top:1rem;
          }
        }

        h4{
            font-size: 2.5rem;
            font-weight:$regular;
            @include tablet{
                font-size: 3.5rem;
                
            }
        }
        p{
            padding-left:5% ;
            padding-right:5% ;
            font-weight: $delgada;
            @include tablet{
            font-size: $p_tab;
            }
            @include desktop{
              font-size: $p_des;
            }
        }
        

    }
} 

.introduccion__p1-bold{
  font-weight: $bold;
}

.wrapper {
    
    //background-image: url(../../img/textura1.webp);
    background-size: 100% 100%;
    
}

.introduccion__fixed-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh; 
  
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.fixed-bg h3 {
  display: table-cell;
  vertical-align: middle; 
}

/* first section */

.bg-1 {
  background-image: url('../../img/1.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
  background-size: 160%;
  background-position-x: 60%;
  
  }
}

/* third section */

.bg-2 {
  background-image: url('../../img/5.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
    background-size: 160%;
    background-position-x: 60%;
  }
}
/* fifth section */

.bg-3 {
  background-image: url('../../img/3.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
    background-size: 160%;
    background-position-x: 60%;
  }
}
.bg-4 {
  background-image: url('../../img/4.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
    background-size: 160%;
    background-position-x: 60%;
  }
}
.bg-5 {
  background-image: url('../../img/8.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
    background-size: 160%;
    background-position-x: 60%;
  }
}
.bg-6 {
  background-image: url('../../img/7.webp');
  background-size: 280%;
  background-position-x: 53%;
  @include desktop {
    background-size: 160%;
    background-position-x: 60%;
  }
}

  .introduccion__img{
    transform: scale(0.3,0.3);
  }
  //Astrologia viva
  .text-focus-in h3 {
    -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.demo{
  border:none;
}

.demo a {
  position: absolute;
  top:90vh;
  left: 50%;
  z-index: 99;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #000;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  

  &  #section2{
    top:190vh;
    
  }
}
.demo a:hover {
  opacity: .3;
}
#section01 a {
  padding-top: 60px;
}
#section01 a span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid rgb(0, 0, 0);
  border-bottom: 1px solid rgb(0, 0, 0);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
  padding-top: 3%;
}