//Fuentes
$fuente:'Quicksand', sans-serif;
$fuente-titulo:'Cinzel', serif;
$fuente2: 'Josefin Sans', sans-serif;


//Colores



//Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1200px;
$tv: 2600px;

//Fuentes
$p_tel:1.8rem;
$p_tab:1.9rem;
$p_des:2.2rem;
$delgada:300;
$regular: 400;
$bold: 600;


//Separaciones
$separacion: 5rem;
$separacion_peque: 2.5rem;
$separacion_grande: 10rem;


