.main-content {
    display: flex;
    font-family:$fuente2;
    // @include tablet {
    // font-family: MKSansserifLightTallX;
    //}
  
}

.bg-principal {
  background-attachment: fixed;
  background-repeat: no-repeat;
    //background-size: contain;
  background-position: center center;
  background-image: url('../../img/6.webp');
  background-size: 280%;
  background-position-x: 52%;
  @include telefono {
    background-size: 200%;
    background-position-x: 52%;
  }
  @include desktop {
  background-size: 160%;
  background-position-x: 58%;
  
  }
}

.bg-principal1 {
  background-color:black;
}


.main__main-content{
  display: flex;
  justify-content: center;
  width: 100vw;
  height:100vh;
  position: relative;
  
  @include tablet {
      
    img{  
        max-width: 100%;
    }
  }
  img{
      max-width: 140%;
      -webkit-animation: heartbeat 3s ease-in-out infinite both;
      animation: heartbeat 3s ease-in-out infinite both;
      @include tablet{
      margin-left:-8%;
      }
      @include desktop{
        margin-left: -12%;
        padding-top: 5%;
        
      }  
    }
}

.main__titulo{
  position: absolute;
  
  h1{
      
      font-size: 3rem;
      margin-top: 15rem;
      margin-left: 1rem;
      letter-spacing: 0.5rem;
      
      
      @include tablet{
          font-size: 4.8rem;
          margin-top: 15%;
          margin-left: 5%;
          letter-spacing: 0.8rem;
      }
      @include desktop{
          font-size: 6rem;
          margin-top: 20%;
          margin-left:1%;
          letter-spacing: 1.3rem;
      }
  }


}
// HOLA SOY PEPPA
// .main__text{
//   position: absolute;
//   bottom: 5%;
//   h3{
//   font-size: 2rem;
//     @include tablet{
//       font-size:3rem;
//     }
//   }
// }
.main__img360{
  position: absolute;
  bottom:5%;
  right:5%;
  width: 12%;
  height:12%;
  @include tablet{
    bottom:45%;
    right:2%;
    width: 3%;
    height:3%;
  }
}

.tracking-in-contract h1 {
  -webkit-animation: tracking-in-contract 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.8s both;
          animation: tracking-in-contract 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.8s both;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-8-3 20:9:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract {
    0% {
      letter-spacing: 0.1rem;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: 1rem;
      opacity: 1;
    }
  }
@keyframes tracking-in-contract {
    0% {
      letter-spacing: 0.1rem;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: 1rem;
      opacity: 1;
    }
  }

@include tablet {
   @-webkit-keyframes tracking-in-contract {
     0% {
       letter-spacing: 0.1rem;
       opacity: 0;
     }
     40% {
       opacity: 0.6;
     }
     100% {
       letter-spacing: 3rem;
       opacity: 1;
     }
  }
  @keyframes tracking-in-contract {
      0% {
        letter-spacing: 0.1rem;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        letter-spacing: 3rem;
        opacity: 1;
      }
  }
}

 @include desktop{
        @-webkit-keyframes tracking-in-contract {
        0% {
          letter-spacing: 0.1rem;
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          letter-spacing: 5rem;
          opacity: 1;
        }
      }
      @keyframes tracking-in-contract {
        0% {
          letter-spacing: 0.1rem;
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          letter-spacing: 5rem;
          opacity: 1;
        }
      }

}

     


/* ----------------------------------------------
 * Generated by Animista on 2021-8-2 8:25:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }

  .slide-in-bottom {
    -webkit-animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
            animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-2-17 15:46:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
