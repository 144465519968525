@mixin telefono {
    @media (min-width: $telefono) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin tv {
    @media (min-width: $tv) {
        @content;
    }
}

@mixin responsive {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin grid($columnas) {
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $separacion;
    justify-content: center;
}

