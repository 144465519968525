html {
    font-size: 62.5%;
    box-sizing: border-box;
    
}

*, *::before, *:after {
    box-sizing: inherit;
}

body {
    // font-family: Avenir Next; 
    // src: local('AvenirNext.ttc');
    font-size: 1.6rem;
    font-weight:lighter;
    font-family: $fuente2;
    
    
}
// @font-face { 
//     font-family: Avenir Next; 
//     src: local('AvenirNext.ttc');
// }
// @font-face { 
//     font-family: MKSansserifLightTallX; 
//     src: local('MKSansserifLightTallX.ttf');
// }


p { 
    //font-weight:lighter;
    font-size: $p_tel;
    @include tablet {
        font-size: $p_tab;
    }
    @include desktop {
        font-size: $p_des;
    }
    
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

h1 {
    font-size: 4rem;
    font-weight:normal;
}

h2{ 
    font-size: 3.6rem;
    font-weight:normal;
}

h3{ 
    font-size: 3rem;
    font-weight:normal;
}
h4{
    font-size: 2rem;
    font-weight:normal;
}
li {
    list-style: none;
}
button{
    font-weight:lighter;
}
.contenedor {
    max-width: 120rem;
    width: 95%;
    margin: 0 auto;
    
}
hr{
    width: 100%;
    margin-top: 4%;
    // margin-bottom: 4%;
    border:1px solid rgb(238, 238, 238);
}

