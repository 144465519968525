// .sobremi__banner{
//     background-image:url(../../img/sobremi.webp);
//     background-size: contain;
//     background-position: center; /* Center the image */
//     background-repeat: no-repeat; /* Do not repeat the image */
//     position: inherit;
//     height: 40vh;
    
    
    
//     @include desktop{

//         background-size: cover;
//         height: 70vh;
//     }
        
// }

// .sobremi__titulo{
//     display:flex;
//     justify-content: center;
//     align-items: center;
// }

// .sobremi_texto{
//     padding:5%;
// }

span {
    font-weight: 100;
  }

.sobremi__titulo {
  display:flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;

  @include desktop {
    padding-top: 1%;
  }
  
  h4{
    font-size: 3rem;
    padding-top: 1%;
    margin-bottom: 0;
    @include tablet{
      padding-top: 0;
    }
  }
  }

.sobremi__wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    font-family: $fuente2;
    font-size: 3rem;
    font-weight: 300 ;
    line-height: 1.15;

    @include desktop{
        grid-auto-rows: repeat(3, 1fr);
        line-height: 1.25;
    }
  }

.sobremi__flex{
    @include tablet{
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 2% 5% 10% 5% ;
    }
}

.sobremi__one {
  grid-column: 1 / 11;
  grid-row: 1;
  
  background-image:url(../../img/sobremi_v.webp);
    background-size: contain;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    position: inherit;
    height:90vh;

    @include tablet{

        grid-column: 1 / 6;
        grid-row: 1;
  
        background-image:url(../../img/sobremi_v.webp);
        background-size: contain;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        position: inherit;
        
        // border: 1px solid red;

    }
}
.sobremi__two {
  grid-column: 1 / 11;
  grid-row: 2;
  padding: 3%;
  text-align: left;
  
  @include tablet{

    grid-column: 5 / 11;
    grid-row: 1;
    padding: 3%;
    text-align: right;
    height:100vh;
    
    // border: 1px solid salmon;
    

}
}
.sobremi__three {
  grid-column: 1 / 11;
  grid-row: 3;
  
  background-image:url(../../img/sobremi_cn.webp);
    background-size: contain;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    position: inherit;
    height: 40vh;
    @include tablet{

        grid-column: 6 / 11;
        grid-row: 2;
  
        background-image:url(../../img/sobremi_cn.webp);
        background-size: contain;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        position: inherit;
        height: 80vh;
        // border: 1px solid red;
        


    }
}
.sobremi__four {
  grid-column: 1 / 11;
  grid-row: 4;
  
  padding: 3%;
  text-align: left;
  //border: 1px solid green;

  @include tablet{

    grid-column: 1 / 7;
    grid-row: 2;
    padding: 3%;
    text-align: left;
    height:80vh;
    
    
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10% 5% 2% 5%;
}

}

// .sobremi__four2{
//     grid-column: 1 / 11;
//   grid-row: 5;
  
//   padding: 3%;
//   text-align: left;
//   //border: 1px solid blue;
//   @include tablet{

//     grid-column: 1 / 11;
//     grid-row: 3;
    
//     text-align: left;
//     // height:30vh;
    
    
//     // display:flex;
//     // flex-direction: column;
//     // justify-content: space-around;
//     padding: 0 3%;
// }
// }
.sobremi__five {
  grid-column: 1 / 11;
  grid-row: 5;
  
  background-image:url(../../img/sobremi_m.webp);
    background-size: contain;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    position: inherit;
    height: 40vh;
    // border: 1px solid green;

    @include tablet{

        grid-column: 1 / 11;
        grid-row: 3;
  
        background-image:url(../../img/sobremi_m.webp);
        background-size: contain;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        position: inherit;
        height: 50vh;
        //border: 1px solid red;
        


    }
}
.sobremi__six {
  grid-column: 1 / 11;
  grid-row: 6;
  
  padding: 0% 3% 10% 3%;
  text-align: left;

  @include tablet{

    grid-column: 1 / 11;
    grid-row: 4;
    padding: 3%;
    text-align: center;
    height:30vh;
    // border: 1px solid blue;
    
    
    padding: 5% 5% ;
}
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}