.consultas__titulo{
    
  height: 15vh;
  max-width: 80vw;
  margin-left: 10%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // font-family: $fuente-titulo;
  
  //border:1px solid pink;
  
  h4{
      font-size: 2.5rem;
      //font-weight:lighter;
      margin-top:2rem;
      margin-bottom: 1rem;
      @include tablet{
          font-size: 3.5rem;
      }
  }
  p{
      @include tablet{
      font-size: 1.8rem;
      }
  }
    
}


.consultas__contenedor{
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 100vw;
  justify-content: space-around;
  align-items: center;
  //padding-top: 10%;
  
  @include tablet {
      flex-direction: row;
      padding-top: 0%;
  }
  .consultas__servicio {
      //border:1px solid black;
    border-radius: 100%;
    height: 15rem;
    width: 15rem;
    position: relative;
    
    
     
    @include telefono{
        height: 18rem;
        width: 18rem;
    }
    @include tablet {
        margin-bottom: 20vh;
    }
    @include desktop{
      height: 35rem;
      width: 35rem;
    }
    @include tv{
        height: 50rem;
        width: 50rem;
    }
    &:hover{
        .img2{
          transform:scale(2.1,2.1);
          @include desktop{
            transform: scale(2, 2);
        }
      }
        .img3{
          transform:scale(1.4,1.4);
          @include desktop{
            transform: scale(2, 2);
          }
        }
        .img1{
            transform: scale(1.5, 1.5);
            @include desktop{
                transform: scale(2, 2);
            }
        }
    }
  }

  .img1,.img2,.img3 {
      
      transition: all 1.5s ease;
      //
      opacity: 1;
      display:block;
      backface-visibility: hidden;
      //border:1px solid black;
  }    
}

.middle {
    //border:1px solid black;
    
  transition: 2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  button {
    background-color: transparent;
    cursor: pointer;
    margin:0 auto;
    border:none;
    font-size: $p_tab;
    font-weight: normal;
    @include tablet{
        font-size: $p_des;
    }
    @include desktop{
        font-size: 3rem;
    }
  }

    
}
.consultas__servicio:hover .img3,.img1,.img2 {
    opacity: 1;
  }
  
.consultas__servicio:hover .middle {
    opacity: 1;
  }
  
// .sombra{ 
//   box-shadow: 0px 0px 15px 1px rgba(112,112,112,0.5);
//   -webkit-box-shadow: 0px 0px 15px 1px rgba(112,112,112,0.5);
//   -moz-box-shadow: 0px 0px 15px 1px rgba(112,112,112,0.5);  
// }



.contenedor__consulta{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  .consultas__img{
    position: absolute;
    opacity: 0.7;
    z-index: 2;
    top:60%;
    left:40%;
    
  
    @include desktop{
      top:10%;
      left:70%;
      padding: 0%;
      height: 70rem;
      width: 70rem;
    }
    
  }
}



.mySwiper{
    --swiper-navigation-color:red;
    --swiper-pagination-color:black;
    
}
.swiper-container { 
    width: 90%;
    height: 85%;
    position: absolute;
    top:10%;
    left:5%;

    &.swiper-container1{
      background: rgb(49,241,250); /* Old browsers */
      background: -moz-linear-gradient(top,  rgba(49,241,250,1) 0%, rgba(49,241,250,1) 0%, rgba(100,245,189,1) 50%, rgba(198,251,70,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(49,241,250,1) 0%,rgba(49,241,250,1) 0%,rgba(100,245,189,1) 50%,rgba(198,251,70,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(49,241,250,1) 0%,rgba(49,241,250,1) 0%,rgba(100,245,189,1) 50%,rgba(198,251,70,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31f1fa', endColorstr='#c6fb46',GradientType=0 ); /* IE6-9 */
      }
    
    &.swiper-container2{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff09d5+0,f69666+50,f69666+50,f4ad4a+79,edbc3e+100 */
      background: rgb(255,9,213); /* Old browsers */
      background: -moz-linear-gradient(top,  rgba(255,9,213,1) 0%, rgba(246,150,102,1) 50%, rgba(246,150,102,1) 50%, rgba(244,173,74,1) 79%, rgba(237,188,62,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,9,213,1) 0%,rgba(246,150,102,1) 50%,rgba(246,150,102,1) 50%,rgba(244,173,74,1) 79%,rgba(237,188,62,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,9,213,1) 0%,rgba(246,150,102,1) 50%,rgba(246,150,102,1) 50%,rgba(244,173,74,1) 79%,rgba(237,188,62,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff09d5', endColorstr='#edbc3e',GradientType=0 ); /* IE6-9 */
    }
    &.swiper-container3{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f16023+0,f5871a+33,faca0a+66,fce13f+100 */
      background: rgb(241,96,35); /* Old browsers */
      background: -moz-linear-gradient(top,  rgba(241,96,35,1) 0%, rgba(245,135,26,1) 33%, rgba(250,202,10,1) 66%, rgba(252,225,63,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(241,96,35,1) 0%,rgba(245,135,26,1) 33%,rgba(250,202,10,1) 66%,rgba(252,225,63,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(241,96,35,1) 0%,rgba(245,135,26,1) 33%,rgba(250,202,10,1) 66%,rgba(252,225,63,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f16023', endColorstr='#fce13f',GradientType=0 ); /* IE6-9 */

    }
    

    @include tablet{
        width: 100%;
        height: 85%;
        left: 0%;
        top:10%;
        
    }
    @include desktop{
        width: 75%;
        height:50%;
        top: 25%;
    }
}

.swiper-button-prev {
  // visibility: hidden;
  content: url(../../img/flechaIZ.webp);
  z-index: 3;
  width: 5%;
  height:5%;
  @include tablet{
    visibility: visible;
    width: auto;
    content: url(../../img/flechaIZ.webp);
    top:35%;
  }
  @include desktop{
    top:50%;
  }
}

.swiper-button-next {
  // visibility: hidden;
  visibility: visible;
  // width: auto;
  content: url(../../img/flechaDX.webp);
  z-index: 3;
  width: 5%;
  height:5%;
  @include tablet{
    visibility: visible;
    width: auto;
    content: url(../../img/flechaDX.webp);
    top:35%;
  }
  @include desktop {
    left:70%;
    top: 50%;
    content: url(../../img/flechaDX.webp);
  }
  
}

.swiper-pagination{
  @include tablet{
    width: 7% !important;
  }
  @include desktop {
    width: 55% !important;
  }
}
  
.swiper-slide {
  //font-size: 18px;
  //color:rgb(8, 8, 8);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}
  
.parallax-bg1 {
    
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  // background: linear-gradient(transparent,70%,white 95%);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+70,ffffff+95&1+95,0+70 */
  background: -moz-linear-gradient(left,  rgba(255,255,255,0), 70%, rgba(255,255,255,1) 95%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0), 70%,rgba(255,255,255,1) 95%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,0), 70%,rgba(255,255,255,1) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

  @include desktop{
    // background: linear-gradient(90deg,transparent 15%,40%,white 77%);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+15,ffffff+15,ffffff+77&0+15,1+40,1+77 */
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 15%, 40%, rgba(255,255,255,1) 77%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 15%, 40%,rgba(255,255,255,1) 77%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 15%, 40%,rgba(255,255,255,1) 77%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

  }

  }
  
  .swiper-slide .title {
    font-size: 4rem;
    font-weight: normal;
    text-align: center;
    @include tablet {
      margin-left: 3%;
      margin-top: 10%;
      text-align: left;
    }
    @include desktop {
      
      margin-top: 2%;
    }
  }
  
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  
  .swiper-slide .text {
    
    font-size: $p_tel;
    max-width: 500px;
    line-height: 1.3;
    margin-top: 20%;
    text-align: center;

    @include tablet {
        font-size: $p_tab;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 3%;
        margin-top:15%;
        text-align: left;

    }

    
    @include desktop{
        font-size: $p_des;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        margin-top:2%;
        
        
    }
  }


  .consulta__cn{
    background:linear-gradient(to right,#dafd2c,#92f884,#51f3d6);
  }

  .consulta__rs{
      background:linear-gradient(to right, #ec8233, #f76a50, #fc1db0);
  }

  .consulta__s{
      background:linear-gradient(to right, #fdf255, #f9cb0b, #f16223);
  }

  hr.sep-1 {
    position: relative;
    width: 70%;
    display: block;
    margin-top: 4em;
    margin-bottom: 4em;
    height: 1px;
    border:none;
    background: linear-gradient(to right, #00b9ff, #59d941);
    background-size: 1.5rem, 100%;
    transform: rotate(3deg);
    
  }

  .focus-in-contract-bck {
	-webkit-animation: focus-in-contract-bck 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract-bck 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-8 20:41:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
 @-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

  

  
  